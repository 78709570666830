import { useMemo } from "react";

import { isImage } from "@utils/url";

import { EmojiIcon, File, ImageIcon, Notion, SlackColor } from "@ui/icon";
import { ResourceType } from "@api";

interface LinkProps {
  url: string;
  icon?: string;
  type?: ResourceType;
}

const toDynamicFavicon = (url: string) =>
  `https://www.google.com/s2/favicons?domain=${url}`;

export const LinkIcon = ({ url, icon, type }: LinkProps) => {
  return useMemo(
    () =>
      isImage(url) ? (
        <ImageIcon url={url} />
      ) : icon?.startsWith("http") ? (
        <ImageIcon url={icon} />
      ) : !!icon && icon.length < 3 ? (
        <EmojiIcon emoji={icon} />
      ) : icon !== "never" && url?.includes("slack.") ? (
        <SlackColor />
      ) : icon !== "never" && url?.includes("notion.") ? (
        <Notion />
      ) : type === "file" ? (
        <File />
      ) : (
        <ImageIcon url={toDynamicFavicon(url)} />
      ),
    [url, icon]
  );
};

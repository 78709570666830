import { isFunction } from "lodash";
import { Dispatch, SetStateAction } from "react";

export type SetStateFn<T> = Dispatch<SetStateAction<T>>;

export type ComponentOrNode =
  | React.ReactElement
  | React.FunctionComponent<any>
  | null
  | false;

export const isComponent = <T, O>(
  c: React.ComponentType<T> | O
): c is React.FunctionComponent<T> => isFunction(c);

import { CSSProperties, useMemo } from "react";

import { Team } from "@api";

import { cx } from "@utils/class-names";
import { Maybe } from "@utils/maybe";
import { fromColor, toColorVar, toInverseColorVar } from "@utils/color";
import { use } from "@utils/fn";
import { Lock } from "./svgs";

import styles from "./team.module.css";

type Props = {
  team: Maybe<Team>;
  className?: string;
  style?: CSSProperties;
};

export const TeamIcon = ({ team, className, style: _style }: Props) => {
  const style = useMemo(
    () =>
      team?.color
        ? use(fromColor(team.color), ([color, shade]) => ({
            ..._style,
            backgroundColor: toColorVar(color, shade),
            color: toInverseColorVar(color, shade),
          }))
        : _style,
    [_style, team?.color]
  );
  return (
    <span className={cx(styles.team, className)} style={style}>
      {team ? team?.icon || team?.name.substring(0, 1) : <Lock />}
    </span>
  );
};

import { getDay } from "date-fns";
import { warn } from "./debug";
import { Fn } from "./fn";
import { isDefined, Maybe } from "./maybe";

const STRIP_TIMEZONE = /(Z)|([+-]\d{2}:\d{2})/g;
const CAL_DATE = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d{1,3})?)?$/;
const POINT_DATE =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?(Z|[+-]\d{2}:\d{2})?$/;

// Whether to respect UTC or your local timezone whenc converting between
export type Method = "utc" | "local";

// Types
export type DirtyDate = Date;
export type ISODate = string;
export type CalDate = string;
export type PointDate = string;

// Calendar datetimes represent the platonic idea of a date and time. It therefore is always interpreted as the users local time.
// Point datetimes represent a specific point in time, and are always interpreted as UTC.
export type DateMode = "calendar" | "point";

// Gross
export const isDirtyDate = <O>(d: DirtyDate | ISODate | O): d is DirtyDate =>
  d instanceof Date;

export function toDirtyDate(d: PointDate): DirtyDate;
export function toDirtyDate(d: CalDate, method: Method): DirtyDate;
export function toDirtyDate(d: ISODate, method: Method = "utc"): DirtyDate {
  const isCal = isCalDate(d);

  if (isCal && !method) {
    throw new Error("CalDate must specify a conversion method.");
  }

  // Interpret the Calendar date as UTC
  if (isCal && method === "utc") {
    return new Date(`${d}Z`);
  }

  // Interpret the calendar date as local
  if (isCal && method === "local") {
    return new Date(d);
  }

  return new Date(d);
}

export function fromCalDate(d: CalDate, method?: Method): Date;
export function fromCalDate(d: Maybe<CalDate>, method?: Method): Maybe<Date>;
export function fromCalDate(
  d: Maybe<CalDate>,
  method: Method = "local"
): Maybe<Date> {
  return d ? toDirtyDate(d, method) : undefined;
}

export function fromPointDate(d: PointDate): Date;
export function fromPointDate(d: Maybe<PointDate>): Maybe<Date>;
export function fromPointDate(d: Maybe<PointDate>): Maybe<Date> {
  return d ? toDirtyDate(d) : undefined;
}

export function fromISO(d: PointDate): Date;
export function fromISO(d: Maybe<PointDate>): Maybe<Date>;
export function fromISO(d: CalDate, method?: Method): Date;
export function fromISO(d: Maybe<CalDate>, method?: Method): Maybe<Date>;
export function fromISO(d: Maybe<ISODate>, method?: Method): Maybe<Date> {
  if (!d) {
    return undefined;
  }

  return isPointDate(d) ? fromPointDate(d) : fromCalDate(d, method || "local");
}

// Validates the above rules
export const isCalDate = (d: ISODate) => CAL_DATE.test(d);
export const isPointDate = (d: ISODate) => POINT_DATE.test(d);
export const isISODate = (d: ISODate) => isCalDate(d) || isPointDate(d);

const pad = (n: number, length: number = 2) => String(n).padStart(length, "0");

// Timezone agnostic conversion
export const toCalDate = (d: DirtyDate, method: Method) => {
  return method === "utc"
    ? convertToCalDate(d.toISOString(), "utc")
    : `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}T${pad(
        d.getHours()
      )}:${pad(d.getMinutes())}:${pad(d.getSeconds())}.${pad(
        d.getMilliseconds(),
        3
      )}`;
};

// Server side convenience function
export const toCalDateSS = (d: DirtyDate) => {
  if (!(process.env.TZ === "UTC" || process.env.TZ === ":UTC")) {
    warn("Server must be in UTC mode.", { tz: process.env.TZ });
  }

  return toCalDate(d, "local");
};

// Respects timezones and formats in the base Z format
export const toPointDate = (d: DirtyDate) => d.toISOString();

export const toISODate = (
  d: DirtyDate,
  type: "point" | "calendar",
  method?: Method
) => (type === "point" ? toPointDate(d) : toCalDate(d, method || "local"));

// What the CalDate was in UTC or where you are
export const convertToCalDate = (
  d: PointDate,
  method: "utc" | "local"
): CalDate => {
  if (isCalDate(d)) {
    return d;
  }

  if (!isPointDate(d)) {
    throw new Error(`Invalid ISO date ${d}.`);
  }

  return method === "utc"
    ? d.replace(STRIP_TIMEZONE, "")
    : toCalDate(new Date(d), method);
};

// What the PointDate was when it was this CalDate in UTC or where you are
export const convertToPointDate = (
  d: CalDate,
  method: "utc" | "local"
): PointDate => {
  if (isPointDate(d)) {
    return d;
  }

  if (!isCalDate(d)) {
    throw new Error(`Invalid ISOCalDate ${d}.`);
  }

  if (method === "utc") {
    return `${d}Z`;
  }

  return toPointDate(new Date(d));
};

type When<C, V> = C extends undefined ? undefined : V;

// prettier-ignore
export function useCalDate<D extends Maybe<CalDate>>(d: D, fn: Fn<When<D, Date>, When<D, Date>>): D;
// prettier-ignore
export function useCalDate<R, D extends Maybe<CalDate>>( d: D, fn: Fn<When<D, Date>, When<D, R>>): R;
// prettier-ignore
export function useCalDate<R>(d: Maybe<CalDate>, fn: Fn<Maybe<Date>, Date | R>): Maybe<CalDate | R> {
  const result = fn(fromCalDate(d, "local"));
  if (!isDefined(result)) {
    return undefined;
  }
  return isDirtyDate(result) ? toCalDate(result, "local") : result;
}

// prettier-ignore
export function usePointDate<D extends Maybe<PointDate>>(d: D, fn: Fn<When<D, Date>, When<D, Date>>): D;
// prettier-ignore
export function usePointDate<D extends Maybe<PointDate>, R>(d: D, fn: Fn<When<D, Date>, When<D, R>>): R;
// prettier-ignore
export function usePointDate<R>(
  d: PointDate,
  fn: Fn<Maybe<Date>, Date | R>
): PointDate | R {
  const result = fn(fromPointDate(d));
  return isDirtyDate(result) ? toPointDate(result) : result;
}

// prettier-ignore
export function useISODate<D extends Maybe<ISODate>>(d: D, fn: Fn<When<D, Date>, When<D, Date>>): D;
// prettier-ignore
export function useISODate<D extends Maybe<ISODate>, R>(d: D, fn: Fn<When<D, Date>, When<D, R>>): R;
// prettier-ignore
export function useISODate<R>(d: ISODate, fn: Fn<Maybe<Date>, Date | R>): ISODate | R {
  //TODO:
  return (isCalDate(d) ? useCalDate(d, fn) : usePointDate(d, fn)) as any; 
}
// export const useISODate = (d: ISODate, fn: Fn<Date, Date>): ISODate =>

export const now = () => toPointDate(new Date());

export const ensureISODate = (d: Maybe<ISODate | Date>) => {
  if (!d) {
    return;
  }
  if (isDirtyDate(d)) {
    return toPointDate(d);
  }
  return d;
};
